import Splide from '@splidejs/splide';
document.addEventListener('vue-mounted', () => {
        let elem = document.querySelector('#main-splide');
        if (elem != null && elem.children.length > 0) {
            // SplideJS Slider. See full docs at:
            // https://splidejs.com/
            let splide = new Splide(elem, {
                type         : 'fade',
                rewind       : true,
                height       : '80vh',
                perPage      : 1,
                autoplay     : false,
                pauseOnHover : false, // must be false
                pauseOnFocus : false, // must be false
                resetProgress: true
            }).mount();

            let button = document.querySelector('.splide__play-pause');

            if (button) {
                let pausedClass = 'is-paused';

                // Remove the paused class and change the label to "Pause".
                splide.on('autoplay:play', function () {
                    button.classList.remove(pausedClass);
                    button.textContent = 'Pause';
                    button.setAttribute('aria-label', 'Pause Autoplay');
                });

                // Add the paused class and change the label to "Play".
                splide.on('autoplay:pause', function () {
                    button.classList.add(pausedClass);
                    button.textContent = 'Play';
                    button.setAttribute('aria-label', 'Start Autoplay');
                });

                // Toggle play/pause when the button is clicked.
                splide.on('click', function () {
                    let flag     = 99;
                    let Autoplay = splide.Components.Autoplay;

                    if (button.classList.contains(pausedClass)) {
                        Autoplay.play(flag);
                    } else {
                        Autoplay.pause(flag);
                    }
                }, button);
            }
        }
})
