import Auth from '../auth/auth';
import request from 'oc-request';
export default new class CheckoutFormValidation {
    constructor() {
        this.alerta = '.alerta';
        this.form = '_ajax_create_order';
        this.cartItemItem = 'details__order--item';
        this.errorClass = 'bg-danger';
        this.buttonClass = '_create_order_button';
        this.wasValidatedClass = 'was-validated';

        this.eventHandlers();
    }

    eventHandlers() {
        if (!document.querySelectorAll(`.${this.form}`).length > 0) {
            return;
        }

        const _this = this;
        const forms = document.querySelectorAll(`.${_this.form}`);
        const button = document.querySelector(`.${_this.buttonClass}`);

        document.addEventListener('readystatechange', (e) => {
            if (e.target.readyState == 'complete') {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.querySelectorAll('.needs-validation');
                var form = document.querySelector('.create-order-form');
                // Loop over them and prevent submission
                form.addEventListener('submit', function(event) {
                    event.preventDefault();
                    event.stopPropagation();
                    let validate_errors = null;
                    Array.from(forms).forEach( f => {
                        if ( f.checkValidity() === false ) {
                            validate_errors = true
                            f.classList.add('was-validated');
                        }
                    } )
                    if (validate_errors) {
                        event.preventDefault();
                        event.stopPropagation();
                        setTimeout(() => {
                            var alerta = document.querySelector(`${_this.alerta}`);
                            alerta.classList.remove('alert-success');
                            alerta.classList.add('alert-danger');
                            alerta.innerText = document.querySelector(`.${_this.buttonClass}`).getAttribute('data-form-errors');
                            alerta.classList.add('show');

                            setTimeout(() => {
                                alerta.classList.remove('show');
                            }, 5000)
                        }, 300)
                    }else{
                        event.preventDefault();
                        event.stopPropagation();
                        _this.updateCart()
                    }
                }, false);
            }
        }, false);
    }

    createOrder(preloader) {

        const form = document.querySelector(`.${this.form}`),
            button = document.querySelector(`.${this.buttonClass}`),
            shipping_disabled = document.querySelector('#shipping-form')?document.querySelector('#shipping-form').getAttribute('data-disabled'):true,
            payment_method_id = form.querySelector('input[name="payment_method_id"]:checked'),
            shipping_type_id = document.querySelector('input[name="shipping_type_id"]'),
            isShipping = document.querySelector('#shipping-list'),
            _this = this;

        let data = {
            'order': {
                'payment_method_id': payment_method_id?payment_method_id.value:null,
                'shipping_type_id': shipping_type_id?shipping_type_id.value:null,                
            },
            'user': {
                'name': document.querySelector('input[name="name"]').value,
                'last_name': document.querySelector('input[name="last_name"]')?document.querySelector('input[name="last_name"]').value:null,
                'email': document.querySelector('input[name="email"]').value,
                'phone': document.querySelector('input[name="phone"]').value,
                'rut': document.querySelector('input[name="rut"]').value,
                'agencia': document.querySelector('input[name="agencia"]').value,
                'razon_social': document.querySelector('textarea[name="razon_social"]').value,
                'comentarios': document.querySelector('textarea[name="comentarios"]').value,
                'department': document.querySelector('input[name="department"]').value,
                'total_price': document.querySelector('input[name="total_price"]').value,
            },
            'shipping_address': {
                'address1': !shipping_disabled?form.querySelector('input[name="shipping_address1"]').value:null,
                'address2': !shipping_disabled?form.querySelector('input[name="shipping_address2"]').value:null, 
                
            },
            'billing_address': {
                'address1': form.querySelector('input[name="billing_address1"]').value,
                'address2': form.querySelector('input[name="billing_address2"]')?form.querySelector('input[name="billing_address2"]').value:null,                
            }
        };

        //Si hay tipos de envio para seleccionar
        if (shipping_type_id != null) {
            this.clearNotAvailableCartPosition();
            request.sendData('MakeOrder::onCreate', {
                'data': data,
                success: function (response) {
                    document.querySelector(`.${_this.buttonClass}`).setAttribute('disabled', false)
                    if (!!response && (response.status || !!response['X_OCTOBER_REDIRECT'])) {
                        this.success(response);
                    } else {
                        button.setAttribute('data-content', response.message);
                        _this.markNotAvailableOfferPosition(response);
                        setTimeout(function () {
                            var alert = document.querySelector(`${_this.alerta}`);
                            alert.innerText = document.querySelector(`.${_this.buttonClass}`).getAttribute('data-error-gateway')
                            alert.classList.add('show');

                            setTimeout(() => {
                                alert.classList.remove('show');
                            }, 5000)
                        }, 300);
                    }
                },
                complete: function () {
                    preloader.classList.remove('is-active');
                }
            });
        }else{
            //Si no hay tipos de envio para sellecionar
            if(isShipping == null){
                this.clearNotAvailableCartPosition();
                request.sendData('MakeOrder::onCreate', {
                    'data': data,
                    success: function (response) {
                        document.querySelector(`.${_this.buttonClass}`).setAttribute('disabled', false)
                        if (!!response && (response.status || !!response['X_OCTOBER_REDIRECT'])) {
                            this.success(response);
                        } else {
                            button.setAttribute('data-content', response.message);
                            _this.markNotAvailableOfferPosition(response);
                            setTimeout(function () {
                                var alert = document.querySelector(`${_this.alerta}`);
                                alert.innerText = document.querySelector(`.${_this.buttonClass}`).getAttribute('data-error-gateway')
                                alert.classList.add('show');

                                setTimeout(() => {
                                    alert.classList.remove('show');
                                }, 5000)
                            }, 300);
                        }
                    },
                    complete: function () {
                        preloader.classList.remove('is-active');
                    }
                });
            }else{
                Auth.alertHandler('#shippingnotset');
                preloader.classList.remove('is-active');
            }
        }
    }

    markNotAvailableOfferPosition(response) {
        if (!response || !response.data || !response.data.cart_position_id) {
            return;
        }

        document.querySelector(`.${this.cartItemItem}[data-position-id="${response.data.cart_position_id}"]`).classList.add(this.errorClass);
    }

    clearNotAvailableCartPosition() {
        document.querySelector(`.${this.cartItemItem}`).classList.remove(this.errorClass);
    }

    updateCart() {
        const _this = this;
        let cart = [],
            data = [],
            errors = false,
            quantity = 0,
            shipping_type_id = document.querySelector('input[name=shipping_type_id]'),
            maxQuantity = 0;
            const preloader = document.querySelector('[data-loader="spinner"]');

        if (preloader) {
            document.querySelectorAll('[data-loader="spinner"]').forEach( el => {
                el.classList.remove('d-none');
            })
        }

        if (shipping_type_id){
        	data.push(['shipping_type_id', shipping_type_id.value]);
        }

        // Item list row
        let cart_list = document.querySelector('.details__order-list').childNodes;

        cart_list.forEach(function (item) {

            let properties = item.querySelectorAll('select[data-target=property_slim]');
            let temp = [];
            let offer_id = ['id', item.getAttribute('data-position-id')];
            quantity = ['quantity', item.querySelector('input[name=quantity]').value];
            maxQuantity = parseInt(item.querySelector('input[name=quantity]').getAttribute('max'));

            if (quantity > maxQuantity) {
                item.classList.add('border border-danger');
                errors = true;
            } else {
                if (item.classList.contains('border border-danger')) {
                    item.classList.remove('border border-danger')
                }
            }

            properties.forEach(function (prop) {
                temp.push([prop.getAttribute('name').toLowerCase(), prop.slim.selected()])
            })

            let object = Object.fromEntries([offer_id, quantity, ['property', Object.fromEntries(temp)]]);
            cart.push(object);
        })

        data.push(['cart', cart]);
        let object = Object.fromEntries(data);

        if (errors == false) {
            request.sendData('Cart::onUpdate', {
                data: object,
                success: function (response) {
                    if (!!response && response.status && !!response.data) {;
                        _this.createOrder(preloader);
                    }
                }
            });
        }
    }
}();
